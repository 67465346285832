import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

function Modal({
  className,
  onClose,
  maskClosable,
  closable,
  visible,
  children,
}) {
  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose(e);
    }
  };

  const close = (e) => {
    if (onClose) {
      onClose(e);
    }
  };
  return (
    <>
      <ModalOverlay visible={visible} />
      <ModalWrapper
        className={className}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={visible}
      >
        <div className="state-inner">
          {closable && (
            <button className="state-close" onClick={close}>
              <img src="https://i.ibb.co/tMwkvHS/inquirex.png" alt="x" />
            </button>
          )}
          <span>
            <b>
              <span style={{ fontSize: "23px" }}>개인정보처리방침</span>
              <br />
              <br />
              개인정보 수집 및 이용에 관한 안내
              <br />
            </b>
            <br />
            <br />
            1. 개인 정보의 수집ㆍ이용 목적
            <br />
            - 본인확인, 예약사항 전달, 각종 혜택 안내, 입주 상담
            <br />
            <br />
            2. 수집하는 개인정보의 항목[필수]
            <br />
            - 기재하시는 성명, 핸드폰 번호
            <br />
            <br />
            3. 개인정보의 보유ㆍ이용 기간
            <br />- 승인하신 때로부터 1년
          </span>
        </div>
      </ModalWrapper>
    </>
  );
}

Modal.propTypes = {
  visible: PropTypes.bool,
};

const ModalWrapper = styled.div`
  box-sizing: border-box;
  display: ${(props) => (props.visible ? "block" : "none")};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;

  .state-inner {
    background-color: #f0f0f0;
    width: 30vw;
    padding: 2vw 2vw 5vw;
    box-shadow: 0px 0px 10px darkgrey;
    margin-top: 10vw;
    margin-left: 5vw;
  }

  .state-close {
    border: none;
    float: right;
  }

  .state-close img {
    width: 1vw;
  }

  h1 {
    clear: both;
    font-size: 1.2vw;
  }
`;

const ModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${(props) => (props.visible ? "block" : "none")};
  position: fixed;
  top: 20;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
`;

export default Modal;
