import React from "react";

import kioskTitle from "../../images/samorder/kiosk_title.svg";
import kioskMobileTitle from "../../images/samorder/kiosk_mobile_title.svg";
import kioskImage from "../../images/samorder/kiosk.png";
import kioskUnionEffect from "../../images/samorder/Union.png";

import styled from "styled-components";

function KioskSection() {
  return (
    <StyledKioskSection>
      <StyledKioskContents>
        <StyledBackgroundColor />
        <StyledTitle>
          최저임금 <strong>9,160원</strong> 알바는 구해지지 않고...😭
        </StyledTitle>
        <StyledImagesContainer>
          <StyledSubTitleImage alt="kiosk section title" src={kioskTitle} />
          <StyledMobileSubTitleImage
            alt="kiosk section title"
            src={kioskMobileTitle}
          />
        </StyledImagesContainer>
      </StyledKioskContents>
      <StyledKioskImage alt="kiosk image" src={kioskImage} />
    </StyledKioskSection>
  );
}

export default KioskSection;

const StyledKioskSection = styled.section`
  position: relative;
  text-align: center;
`;

const StyledKioskContents = styled.div`
  position: relative;
  background-color: #ffc555;
  overflow: hidden;
  padding: 0 30px;

  @media ${({ theme: { mobile } }) => mobile} {
    padding: 10px 20px 0;
  }

  &:before {
    background: linear-gradient(-150deg, #430000 13px, transparent 0),
      linear-gradient(150deg, #430000 13px, transparent 0);
    background-position: left-top;
    background-repeat: repeat-x;
    background-size: 40px 35px;
    content: " ";
    display: block;
    position: absolute;
    top: 13px;
    left: 40px;
    width: 100%;
    height: 31px;
    transform: scale(2);
    z-index: 3;
  }

  &:after {
    /* background: linear-gradient(-35deg, #ffffff 20px, transparent 0),
      linear-gradient(35deg, #ffffff 20px, transparent 0); */
    background-position: left-bottom;
    background-repeat: repeat-x;
    background-size: 40px 35px;
    content: " ";
    display: block;
    position: absolute;
    bottom: 13px;
    left: 0px;
    width: 100%;
    height: 31px;
    transform: scale(2);
  }
`;

const StyledTitle = styled.div`
  padding: 140px 0 0;
  font-family: "Pretendard-SemiBold";
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 24px;
  position: relative;

  strong {
    font-weight: 800;
  }

  @media ${({ theme: { mobile } }) => mobile} {
    margin-bottom: 14px;
  }
`;

const StyledImagesContainer = styled.div`
  ${({ theme: { flex } }) => flex("column")};
  padding: 0 0 300px;

  @media ${({ theme: { mobile } }) => mobile} {
    padding: 0 0 190px;
  }
`;

const StyledSubTitleImage = styled.img`
  max-width: 440px;
  width: 100%;
  z-index: 2;

  @media ${({ theme: { mobile } }) => mobile} {
    display: none;
  }
`;

const StyledMobileSubTitleImage = styled.img`
  display: none;
  max-width: 191px;
  height: 100%;

  @media ${({ theme: { mobile } }) => mobile} {
    display: inline-block;
    z-index: 2;
    margin-bottom: 20px;
  }
`;

const StyledKioskImage = styled.img`
  width: 100%;
  position: absolute;
  max-width: 543px;
  /* top: -250px; */
  left: 50%;
  top: 85%;
  z-index: 5;
  padding: 0 30px;
  transform: translate(-50%, -50%);

  @media ${({ theme: { mobile } }) => mobile} {
    max-width: 368px;
    bottom: -100px;
  }
`;

const StyledBackgroundColor = styled.div`
  display: block;
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: -10%;
  left: 0;
  /* background-image: url(${kioskUnionEffect}); */
  background: radial-gradient(
      50% 60% at 50% 50%,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 197, 85, 1) 90%
    ),
    url(${kioskUnionEffect});
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.2;
  background-position: 50%;
  /* z-index: 0; */
  
  // max-width: 1520px 가운데로 맞추기
  @media screen and (max-width: 1520px) {
    /* background: radial-gradient(
      50% 60% at 50% 50%,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 197, 85, 1) 90%
      ),
      url(${kioskUnionEffect}); */
      /* background-position: 50%; */
  }

  @media ${({ theme: { mobile } }) => mobile} {
    transform: scale(2);
  }
`;
