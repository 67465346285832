import { DefaultTheme } from "styled-components";

const flex = (direction = "row", justify = "center", align = "center") => `
  display: flex;
  justify-content: ${justify};
  align-items: ${align};
  flex-direction: ${direction};
  `;
//
const colors = {
  black: "#0E0E0E",
  white: "#ffffff",
  burgundy: "#430000",
  orange: "#FFBB3E",
  oranageGradiant: "linear-gradient(90deg, #F7971E 0%, #FFD200 100%);",
  lightBrown: "#893a00",
  darkBrown: "#452712",
  lightGreen: "#085a1f",
  darkGreen: "#3B432B",
  purple: "#321245",
  darkBlue: "#122945",
};

const size = {
  mobile: "480px",
  tablet: "768px",
};

const theme: DefaultTheme = {
  flex,
  colors,
  mobile: `(max-width: ${size.mobile})`,
  tablet: `(max-width: ${size.tablet})`,
};

export default theme;
