import React, { useCallback, useRef, useState } from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import backgroundImage from "../../images/samorder/bg_img.png";
import pdfImage from "../../images/samorder/pdf.png";
import { theme } from "./styles";
import { MOBILE_SIZE, TABLET_SIZE } from "./common/constants";
import swal from "sweetalert";
import axios from "axios";
import ImgModal from "../modal/statement";

function FormSection() {
  const isMobile = useMediaQuery({ maxWidth: MOBILE_SIZE });
  const isTablet = useMediaQuery({ maxWidth: TABLET_SIZE });

  const title = useRef<string>("샘오더는 \n 믿을 수 있습니다.");

  const [info, setInfo] = useState({
    name: "",
    phone: "",
    purpose: "",
    content: "",
    road: "샘오더",
  });
  const [typeset, setType] = useState("");
  const [check, setCheck] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const openModal = () => {
    setModalVisible(true);
  };
  const closeModal = () => {
    setModalVisible(false);
  };

  function TextItem({ text }: { text: string }) {
    const MemoizedTextItem = useCallback(
      () => (
        <>
          {text.split("\n").map((txt) => (
            <React.Fragment key={txt}>
              {txt}
              <br />
            </React.Fragment>
          ))}
        </>
      ),
      [isMobile]
    );
    return <MemoizedTextItem />;
  }

  const TextContentA = useCallback(() => {
    if (isMobile) {
      return (
        <>
          키오스크를 직접 개발 / 제조하여 만든
          <br />
          샘오더는 많은 프랜차이즈 & 매장에서
          <br />
          사용하여
          <span style={{ fontWeight: "bolder" }}>
            우수한 안정성과 보안성을 인증
          </span>{" "}
          받았습니다.
        </>
      );
    }

    return (
      <>
        키오스크를 직접 개발 / 제조하여 만든 샘오더는
        <br /> 많은 프랜차이즈 & 매장에서 사용하여
        <span style={{ fontWeight: "bolder" }}>
          &nbsp; 우수한 안정성과 보안성을 인증
        </span>{" "}
        받았습니다.
      </>
    );
  }, [isMobile]);

  let submitFlag = false;
  const doubleSubmitCheck = () => {
    if (submitFlag) {
      return submitFlag;
    } else {
      submitFlag = true;
      return false;
    }
  };

  const token = "test";

  let config = {
    headers: {
      "X-JWT-TOKEN": token,
    },
  };

  const sendEmail = async () => {
    try {
      await axios.post(
        "https://api.samoffice.co.kr/mailSendSamlab",
        info,
        config
      );
    } catch (err) {
      console.log(err);
    }
  };

  const sendMess = async () => {
    // var info = Object.assign({}, info, {
    //   select: typeset,
    //   phone: 
    // });
    try {
      await axios.post("https://api.samoffice.co.kr/signSMS", info, config);
    } catch (err) {
      console.log(err);
    }
  };

  const handleOnChange = ({ target }) =>
    setInfo({ ...info, [target.name]: target.value });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      info.purpose = typeset;
      if (
        info.name == "" ||
        info.phone == "" 
      ) {
        swal("문의사항 목록을 채워주세요");
        console.log(info);
      } else if (check == false) {
        swal("개인정보 취급방침을 체크해주세요");
        console.log(info);
      } else {
        info.purpose = typeset;
        if (doubleSubmitCheck()) return;

        let response = await axios.post(
          "https://api.samoffice.co.kr/signupSamlab",
          info,
          config
        );

        if (response.status == 200) {
          sendEmail();
          sendMess();
          setInfo({
            ...info,
            name: "",
            phone: "",
            purpose: "",
            content: "",
          });
          setCheck(false);
          setType("");
          swal("신청되었습니다");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <StyledBackgroundImage>
      <StyledTitle>
        {isMobile ? <TextItem text={title.current} /> : title.current}
      </StyledTitle>
      <StyledContentsA>
        <TextContentA />
      </StyledContentsA>
      <StyledContentsB>
        상담량이 많아 상담신청이 조기 마감될 수 있습니다. 지금 바로
        상담신청하세요!
      </StyledContentsB>
      <StyledFormWrapper>
        <StyledInputWrapper>
          <StyledLabel htmlFor="name">
            이름 <span style={{ color: "#FE8D5C", marginLeft: 8 }}>* 필수</span>
            <StyledInput
              id="name"
              name="name"
              type="text"
              onChange={handleOnChange}
              value={info.name}
              placeholder="성함을 입력해 주세요"
            />
          </StyledLabel>
          <StyledLabel htmlFor="phone-number">
            휴대폰 번호{" "}
            <span style={{ color: "#FE8D5C", marginLeft: 8 }}>* 필수</span>
            <StyledInput
              id="phone-number"
              name="phone"
              type="text"
              onChange={handleOnChange}
              value={info.phone}
              placeholder="휴대폰 번호를 입력해주세요"
            />
          </StyledLabel>
        </StyledInputWrapper>
        <StyledCheckBoxWrapper>
          <StyledCommonTitleDiv>
            문의목적
          </StyledCommonTitleDiv>
          <StyledCheckBoxLabelWrapper>
            <StyledCheckBoxLabel htmlFor="#">
              <StyledCheckbox
                type="checkbox"
                name="inquiry"
                value="default"
                onChange={({ target }) =>
                  setType(target.parentNode.textContent)
                }
              />{" "}
              견적문의
            </StyledCheckBoxLabel>
            <StyledCheckBoxLabel htmlFor="#">
              <StyledCheckbox
                type="checkbox"
                name="inquiry"
                value="default"
                onChange={({ target }) =>
                  setType(target.parentNode.textContent)
                }
              />{" "}
              실물체험
            </StyledCheckBoxLabel>
            <StyledCheckBoxLabel htmlFor="#">
              <StyledCheckbox
                type="checkbox"
                name="inquiry"
                value="default"
                onChange={({ target }) =>
                  setType(target.parentNode.textContent)
                }
              />{" "}
              기술문의
            </StyledCheckBoxLabel>
            <StyledCheckBoxLabel htmlFor="#">
              <StyledCheckbox
                type="checkbox"
                name="inquiry"
                value="default"
                onChange={({ target }) =>
                  setType(target.parentNode.textContent)
                }
              />{" "}
              렌탈문의
            </StyledCheckBoxLabel>
            <StyledCheckBoxLabel htmlFor="#">
              <StyledCheckbox
                type="checkbox"
                name="inquiry"
                value="default"
                onChange={({ target }) =>
                  setType(target.parentNode.textContent)
                }
              />{" "}
              기타
            </StyledCheckBoxLabel>
          </StyledCheckBoxLabelWrapper>
          <StyledCommonTitleDiv>
            문의내용
          </StyledCommonTitleDiv>
          <StyledTextArea
            name="content"
            cols="30"
            rows="5"
            value={info.content}
            onChange={handleOnChange}
            placeholder="문의내용을 입력해주세요."
          />
        </StyledCheckBoxWrapper>
        <StyledTermsDiv>
          <StyledCheckBoxLabel htmlFor="#">
            <StyledCheckbox
              type="checkbox"
              id="ischecked"
              value={check}
              onChange={setCheck}
            />
            <span onClick={openModal}>개인정보취급방침</span>을 읽었으며 이에
            동의합니다.
          </StyledCheckBoxLabel>
        </StyledTermsDiv>
        <StyledSubmitButton onClick={handleSubmit}>문의하기</StyledSubmitButton>
        <StyledDownloadDiv>
          <img src={pdfImage} alt="img" />
          <span
            style={{
              cursor: "pointer",
            }}
          >
            제품 브로슈어 다운로드
          </span>
        </StyledDownloadDiv>
      </StyledFormWrapper>
      {modalVisible && (
        <ImgModal
          visible={modalVisible}
          closable={true}
          maskClosable={true}
          onClose={closeModal}
        ></ImgModal>
      )}
    </StyledBackgroundImage>
  );
}

const StyledCommonTitleDiv = styled.div`
  color: #ffffff;
  margin-bottom: 18px;

  @media ${theme.tablet} {
    margin-bottom: 5px;
  }
`;

const StyledBackgroundImage = styled.section`
  /* width: 100%; */
  position: relative;
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  padding: 160px 37px 180px;
  overflow: hidden;

  // 자식 DOM 에 영향 주는 부분
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &:before {
    background: linear-gradient(-150deg, #ffffff 12px, transparent 0),
      linear-gradient(150deg, #ffffff 12px, transparent 0);
    background-position: left-top;
    background-repeat: repeat-x;
    background-size: 40px 35px;
    content: " ";
    display: block;
    position: absolute;
    top: 13px;
    left: 40px;
    width: 100%;
    height: 31px;
    transform: scale(2);
    z-index: 3;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(44, 7, 7, 0.9);
    mix-blend-mode: multiply;
  }

  @media ${theme.mobile} {
    /* padding: 110px 37px 150px; */
    &:before {background-position: center}
  }
`;

const StyledTitle = styled.div`
  font-family: "Pretendard-ExtraBold";
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 58px;

  text-transform: uppercase;
  color: #ffffff;

  z-index: 1;

  margin-bottom: 40px;

  @media screen and ${theme.mobile} {
    font-size: 36px;
    line-height: 43px;
    text-align: center;
  }
`;

const StyledContentsA = styled.div`
  font-family: "Pretendard-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  text-transform: uppercase;

  color: #ffffff;

  z-index: 1;

  margin-bottom: 40px;

  @media screen and ${theme.tablet} {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
  }

  @media screen and ${theme.mobile} {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
`;

const StyledContentsB = styled.div`
  font-family: "Pretendard-Regular";
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 20px;

  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  z-index: 1;

  @media screen and ${theme.tablet} {
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
  }

  @media screen and ${theme.mobile} {
    font-weight: bold;
    font-size: 13px;
    line-height: 20px;
  }
`;

const StyledFormWrapper = styled.div`
  z-index: 1;
  max-width: 700px;

  @media screen and ${theme.mobile} {
    display: flex;
    align-items: center;
    width: 88vw;

    font-weight: bold;
    font-size: 13px;
    line-height: 20px;

    justify-items: center;
    align-items: center;
    flex-direction: column;
  }
`;

const StyledInputWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;

  label:first-child {
    margin-right: 20px;
  }

  @media screen and ${theme.tablet} {
    margin-bottom: 30px;
  }

  @media screen and ${theme.mobile} {
    display: inline-block;
    width: 100%;
    label:first-child {
      margin-bottom: 30px;
    }
  }
`;

const StyledLabel = styled.label`
  display: block;
  color: #ffffff;
  font-family: "Pretendard-SemiBold";
  font-style: normal;
  font-weight: 600;
  width: 100%;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  @media screen and ${theme.mobile} {
    width: 90%;
  }
`;

const StyledInput = styled.input`
  background: #ffffff;
  border-radius: 8px;

  font-family: "Pretendard-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;

  width: 90%;
  padding: 10px 16px 5px;
  margin-top: 5px;

  @media screen and ${theme.tablet} {
    padding: 10px 16px 5px;
    margin-top: 5px;
  }

  @media screen and ${theme.mobile} {
    padding: 10px 16px 5px;
    width: 100%;
  }
`;

const StyledCheckBoxWrapper = styled.div`
  width: 100%;
`;

const StyledCheckBoxLabel = styled.label`
  color: #ffffff;
  display: flex;
  align-items: center;
  position: relative;

  @media screen and ${theme.tablet} {
    position: relative;
    margin-bottom: 25px;
    top: -1.5px;
  }

  @media screen and ${theme.tablet} {
    margin-bottom: 13px;
  }
`;

const StyledCheckbox = styled.input`
  margin-right: 9px;
  background: #ffffff;
  height: 20px;
  width: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: none;
  border-radius: 4px;
  outline: none;
  transition-duration: 0.3s;
  background-color: #fff;
  cursor: pointer;
  :checked {
    background-color: #e82a35;
  }
  &:before {
    transform: scale(1.5);
    content: "✓";
    display: block;
    text-align: center;
    color: #fff;
    position: absolute;
    left: 3px;
    bottom: 3px;
  }
  :active {
    border: 2px solid #e82a35;
  }

  @media screen and ${theme.tablet} {
    margin-right: 5px;
  }

  @media screen and ${theme.mobile} {
    border-radius: 2px;
    margin-right: 7px;
    height: 16px;
    width: 16px;
    &:before {
      bottom: 1px;
      left: 2px;
    } 
  }
`;

const StyledCheckBoxLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0px 30px;

  // @media screen and ${theme.tablet} {
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  //   margin: 10px 0px 30px;
  //   //padding: 5px;
  // }

  @media screen and ${theme.mobile} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 20px;
    column-gap: 25px;
    margin-bottom: 30px;
  }
`;

const StyledTextArea = styled.textarea`
  font-family: "Pretendard-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  background: #ffffff;
  border-radius: 8px;
  padding: 10px 16px;

  letter-spacing: -0.02em;

  width: 95%;
  margin: 5px 0 34px;
  height: 150px;
  resize: none;

  @media screen and ${theme.tablet} {
    width: 95%;
    height: 130px;
  }

  @media screen and ${theme.mobile} {
    width: 90%;
    margin-bottom: 5px;
    height: 130px;
  }
`;

const StyledTermsDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;

  @media screen and ${theme.tablet} {
    margin-bottom: 10px;
  }

  @media screen and ${theme.mobile} {
    margin-bottom: 10px;
  }
`;

const StyledSubmitButton = styled.button`
  font-family: "Pretendard-Bold";
  font-size: 20px;
  border: 1px solid #ffffff;
  border-radius: 8px;
  color: #ffffff;
  background-color: transparent;
  height: 60px;

  width: 80%;
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;

  @media screen and ${theme.mobile} {
    width: 100%;
    margin-bottom: 30px;
  }
`;

const StyledDownloadDiv = styled.div`
  & > span {
    font-family: "Pretendard-Bold";
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;

    letter-spacing: -0.05em;
    text-decoration-line: underline;

    color: #ffffff;
  }

  & > img {
    height: 20px;
    margin-right: 8px;
    vertical-align: middle;
  }

  display: flex;
  justify-content: center;

  @media screen and ${theme.tablet} {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    font-size: 14px;
  }

  @media screen and ${theme.mobile} {
    margin-bottom: 30px;

    & > img {
      height: 20px;
      margin-right: 8px;
      vertical-align: middle;
    }
  }
`;

export default FormSection;
