import React from "react";
import { ThemeProvider } from "styled-components";
import Layout from "../components/layout/layout";
import Banner from "../components/samorder/Banner";
import CardSection from "../components/samorder/CardSection";
import FormSection from "../components/samorder/FormSection";
import KioskSection from "../components/samorder/KioskSection";
// import RightBanner from "../components/samorder/RightBanner";
import MainSection from "../components/samorder/MainSection";
import { theme } from "../components/samorder/styles";
import { Helmet } from "react-helmet";

const pageName = "샘오더 푸드";
const tag_title = `${pageName} - 샘랩`;
const tag_description = `샘랩의 ${pageName} 페이지 입니다.`;

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Banner />
        <Helmet>
        <title>{tag_title}</title>
        <meta name="title" content={tag_title} />
        <meta name="description" content={tag_description} />
        <meta property="og:title" content={tag_title} />
        <meta property="og:description" content={tag_description} />
        <meta property="og:url" content="https://samlab.co.kr/samorderFood" />
        <meta property="twitter:title" content={tag_title} />
        <meta property="twitter:description" content={tag_description} />
        <meta property="twitter:url" content="https://samlab.co.kr/samorderFood" />
      </Helmet>
        <Layout>
          {/* <RightBanner /> */}
          <MainSection />
          <KioskSection />
          <CardSection />
          <FormSection />
        </Layout>
      </ThemeProvider>
    </>
  );
}

export default App;
