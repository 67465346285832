import { createGlobalStyle } from 'styled-components';
import { reset } from 'styled-reset';

const GlobalStyle = createGlobalStyle`
  ${reset}

  *{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  button,
  input {
    background-color: transparent;
    outline: none;
    border: none;
    transition: opacity 0.3s;
}


  button:hover {
    cursor: pointer;
    opacity: 0.8;
  } 

  button:active {
    opacity: 0.7;
  }

  input:focus, textarea:focus { 
    outline: none; 
  }

  p {
    line-height: 1.5;
    letter-spacing: -5%;
  }

  img {
    display: inline-block;
  }


`;

export default GlobalStyle;
