import React from "react";
import styled from "styled-components";

import sectionBackground from "../../images/samorder/card_background.png";
import subTitle from "../../images/samorder/card_section_sub_title.svg";
import firstNumber from "../../images/samorder/뱃지1.svg";
import secondNumber from "../../images/samorder/뱃지2.svg";
import thirdNumber from "../../images/samorder/뱃지3.svg";
import fourthNumber from "../../images/samorder/뱃지4.svg";
import fifthNumber from "../../images/samorder/뱃지5.svg";
import titleText from "../../images/samorder/title_txt.svg";
import mobileTitleText from "../../images/samorder/title_txt(column).svg";
import cardBackground from "../../images/samorder/Rectangle_background.svg";
import man from "../../images/samorder/man.svg";
import woman from "../../images/samorder/woman.svg";
import firstCardImage1 from "../../images/samorder/img1.svg";
import firstCardImage2 from "../../images/samorder/img2.svg";
import secondCardImage1 from "../../images/samorder/img3.svg";
import secondCardImage2 from "../../images/samorder/img4.svg";
import secondCardImage3 from "../../images/samorder/img5.svg";
import thirdCardImage1 from "../../images/samorder/img6.svg";
import thirdCardImage2 from "../../images/samorder/img7.svg";
import fourthCardImage1 from "../../images/samorder/img8.svg";
import fourthCardImage2 from "../../images/samorder/img9.svg";
import fifthCardImage from "../../images/samorder/chart.svg";
import hotIcon from "../../images/samorder/hot.svg";
import { theme } from "./styles";

function CardSection() {
  return (
    <StyledCardSection>
      <StyledCardSectionContents>
        <StyledSubTitle alt="sub title image" src={subTitle} />
        <StyledTitleImage alt="title text" src={titleText} />
        <StyledMobileTitleImage alt="mobile title text" src={mobileTitleText} />
        <StyledCardContainer>
          <StyledManImage alt="man image" src={man} />
          <StyledWomanImage alt="woman image" src={woman} />
          <StyledCard backgroundColor="lightBrown">
            <StyledNumber alt="first card number" src={firstNumber} />
            <StyledFirstCardImages>
              <img alt="quarantine safety1" src={firstCardImage1} />
              <img alt="quarantine safety2" src={firstCardImage2} />
            </StyledFirstCardImages>
            <StyledTexts>
              <StyledCardTitle>
                샘오더는 <strong>방역패스도 확인</strong>합니다.
              </StyledCardTitle>
              <StyledCardParagraph>
                샘오더는 QR인증을 통해 자동으로 백신 접종여부를 확인하여 <br />
                직원이 직접 백신접종여부를 확인하지 않아도 됩니다. <br />
                정부의 코로나방역지침도 샘오더로 간편하게 지키세요!
              </StyledCardParagraph>
            </StyledTexts>
          </StyledCard>
          <StyledCard backgroundColor="darkGreen">
            <StyledNumber alt="second card number" src={secondNumber} />
            <StyledSecondCardImages>
              <img alt="advertisement store1" src={secondCardImage1} />
              <img alt="advertisement store2" src={secondCardImage2} />
              <img alt="advertisement store3" src={secondCardImage3} />
            </StyledSecondCardImages>
            <StyledTexts>
              <StyledCardTitle>
                키오스크로 <strong>매장을 광고</strong>하세요.
              </StyledCardTitle>
              <StyledCardParagraph>
                메뉴별로 상세한 옵션 설정이 가능하여 손님이 특별한 주문을
                <br />
                위해 카운터에 방문하시지 않아도 키오스크를 통해 <br />
                메뉴커스텀이 가능하여 주문 대기시간이 단축됩니다.
              </StyledCardParagraph>
            </StyledTexts>
          </StyledCard>
          <StyledCard backgroundColor="darkBrown">
            <StyledNumber alt="third card number" src={thirdNumber} />
            <StyledThirdCardImages>
              <img alt="menu configure 1" src={thirdCardImage1} />
              <img alt="menu configure 2" src={thirdCardImage2} />
            </StyledThirdCardImages>
            <StyledTexts>
              <StyledCardTitle>
                단 <strong>3초</strong>로 메뉴 구성 완료!
              </StyledCardTitle>
              <StyledCardParagraph>
                카테고리 / 메뉴 등록 부터 수정까지 간편하게!
                <br />
                메뉴에 대한 설명을 적을 수 있으며 할인율과 옵션설정,
                <br />
                품절&숨김 등 디테일한 상품관리가 가능합니다.
                <br />
              </StyledCardParagraph>
            </StyledTexts>
          </StyledCard>
          <StyledCard backgroundColor="purple">
            <StyledNumber alt="fourth card number" src={fourthNumber} />
            <StyledFourthCardImages>
              <img alt="DID 1" src={fourthCardImage1} />
              <img alt="DID 2" src={fourthCardImage2} />
            </StyledFourthCardImages>
            <StyledTexts>
              <StyledFourthCardTitle>
                <strong>100만원 </strong>상당의 <br />
                DID기능 무료 제공!
                <StyledHotIcon>
                  <img alt="hot icon" src={hotIcon} />
                  <div>HOT</div>
                </StyledHotIcon>
              </StyledFourthCardTitle>
              <StyledMobileFourthCardTitle>
                <StyledTabletHotIcon>
                  <img alt="hot icon" src={hotIcon} />
                  <div>HOT</div>
                </StyledTabletHotIcon>
                <strong>100만원</strong> 상당의 DID기능 무료 제공!
              </StyledMobileFourthCardTitle>
              <StyledCardParagraph>
                샘오더는 100만원에 판매되는 DID기능을 무료 탑재하여 <br />
                주방에서 쉽게 주문내역을 확인하고 조리한 음식을 <br />
                고객에게 바로 호출 할 수 있습니다.
              </StyledCardParagraph>
            </StyledTexts>
          </StyledCard>
          <StyledCard backgroundColor="darkBlue">
            <StyledNumber alt="fifth card number" src={fifthNumber} />
            <StyledFifthCardImages>
              <img alt="chart" src={fifthCardImage} />
            </StyledFifthCardImages>
            <StyledTexts>
              <StyledFifthCardTitle>
                실시간 매장 매출,
                <br />
                한눈에 파악하세요!
              </StyledFifthCardTitle>
              <StyledMobileFifthCardTitle>
                실시간 매장 매출, 한눈에 파악하세요!
              </StyledMobileFifthCardTitle>
              <StyledCardParagraph>
                일자별 / 기간별 매출 데이터를 보여줄 뿐만 아니라 <br />
                상품순위로 어떤 상품이 가장 판매량이 높은지 <br />
                그래프로 한눈에 보여주여 효율적인 매장관리가 가능합니다.
              </StyledCardParagraph>
            </StyledTexts>
          </StyledCard>
        </StyledCardContainer>
      </StyledCardSectionContents>
    </StyledCardSection>
  );
}

export default CardSection;

const StyledCardSection = styled.section`
  position: relative;
  background-image: url(${sectionBackground});
  background-size: cover;
  background-repeat: no-repeat;
  padding: 240px 40px 160px;
  overflow: hidden;

  &:before {
    background: linear-gradient(-150deg, #ffc555 13px, transparent 0),
      linear-gradient(150deg, #ffc555 13px, transparent 0);
    background-position: left-top;
    background-repeat: repeat-x;
    background-size: 40px 35px;
    content: " ";
    display: block;
    position: absolute;
    top: 13px;
    left: 40px;
    width: 100%;
    height: 31px;
    transform: scale(2);
    z-index: 3;
  }

  &:after {
    /* background: linear-gradient(-45deg, #ffffff 20px, transparent 0), */
    /* linear-gradient(45deg, #ffffff 20px, transparent 0); */
    background-position: left-bottom;
    background-repeat: repeat-x;
    background-size: 40px 35px;
    content: " ";
    display: block;
    position: absolute;
    bottom: 13px;
    left: 0px;
    width: 100%;
    height: 31px;
    transform: scale(2);
  }

  @media ${({ theme: { mobile } }) => mobile} {
    padding: 160px 20px 200px;
  }
`;

const StyledCardSectionContents = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  text-align: center;
`;

const StyledSubTitle = styled.img`
  max-width: 412px;
  width: 100%;
  margin-bottom: 28px;

  @media ${({ theme: { mobile } }) => mobile} {
    max-width: 275px;
  }
`;

const StyledTitleImage = styled.img`
  display: inline-block;
  width: 100%;
  max-width: 728px;
  margin-bottom: 82px;

  @media ${({ theme: { tablet } }) => tablet} {
    max-width: 473px;
    margin-bottom: 46px;
  }

  @media ${({ theme: { mobile } }) => mobile} {
    display: none;
  }
`;

const StyledCardContainer = styled.ul`
  position: relative;
  text-align: left;

  li:last-child {
    margin-bottom: 0;
  }
`;

const StyledManImage = styled.img`
  position: absolute;
  max-width: 173px;
  left: 0;
  top: -240px;

  @media ${({ theme: { tablet } }) => tablet} {
    max-width: 91px;
    top: -125px;
  }

  @media ${({ theme: { mobile } }) => mobile} {
    max-width: 75px;
    top: -103px;
  }
`;

const StyledWomanImage = styled.img`
  position: absolute;
  max-width: 158px;
  right: 0;
  top: -240px;

  @media ${({ theme: { tablet } }) => tablet} {
    max-width: 84px;
    top: -128px;
  }

  @media ${({ theme: { mobile } }) => mobile} {
    max-width: 70px;
    top: -107px;
  }
`;

const StyledCard = styled.li<{ backgroundColor: string }>`
  ${({ theme: { flex } }) => flex("row", "space-between")}
  margin-bottom: 35px;
  min-height: 360px;
  display: flex;
  position: relative;
  padding: 40px 81px 40px 110px;
  border-radius: 20px;
  &:before {
    background-image: url(${cardBackground});
    background-size: cover;
    opacity: 0.3;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  background-size: cover;
  background-color: ${({ theme: { colors }, backgroundColor }) =>
    colors[backgroundColor]};

  @media ${({ theme: { tablet } }) => tablet} {
    ${({ theme: { flex } }) => flex("column")}
    text-align: center;
    padding: 80px 40px;
  }

  @media ${({ theme: { mobile } }) => mobile} {
    ${({ theme: { flex } }) => flex("column")}
    text-align: center;
    padding: 50px 10px;
    border-radius: 12px;
  }
`;

const StyledNumber = styled.img`
  position: absolute;
  top: -5px;
  left: 30px;

  @media ${({ theme: { tablet } }) => tablet} {
    max-width: 46.5px;
    top: -3px;
  }

  @media ${({ theme: { mobile } }) => mobile} {
    max-width: 36.5px;
  }
`;

const StyledFirstCardImages = styled.div`
  ${({ theme: { flex } }) => flex()};
  margin-right: 20px;
  column-gap: 10px;

  @media ${({ theme: { tablet } }) => tablet} {
    margin-top: 20px;
    margin-right: 0;
  }

  img {
    width: 20vw;
    max-width: 158px;

    @media ${({ theme: { tablet } }) => tablet} {
      max-width: 119px;
    }

    @media ${({ theme: { mobile } }) => mobile} {
      max-width: 150px;
      width: 100vw;
    }

    @media screen and (max-width: 360px) {
      max-width: 100px;
    }
  }
`;

const StyledSecondCardImages = styled.div`
  ${({ theme: { flex } }) => flex()};
  margin-right: 20px;
  column-gap: 10px;

  @media ${({ theme: { tablet } }) => tablet} {
    margin-top: 20px;
  }
  img {
    width: 20vw;
    max-width: 150px;
    @media screen and (max-width: 1125px) {
      margin-right: 0;
      max-width: 110px;
    }
    @media ${({ theme: { tablet } }) => tablet} {
      max-width: 119px;
    }
    @media ${({ theme: { mobile } }) => mobile} {
      max-width: 110px;
    }

    @media screen and (max-width: 360px) {
      max-width: 100px;
    }
  }
`;

const StyledThirdCardImages = styled.div`
  ${({ theme: { flex } }) => flex()};
  margin-right: 20px;
  column-gap: 10px;

  @media ${({ theme: { tablet } }) => tablet} {
    margin-right: 0;
  }

  img {
    &:first-child {
      max-width: 247px;

      @media screen and (max-width: 1125px) {
        max-width: 214px;
      }

      @media ${({ theme: { tablet } }) => tablet} {
        max-width: 185px;
        width: 30vw;
      }

      @media ${({ theme: { mobile } }) => mobile} {
        max-width: 144px;
      }
    }
    &:last-child {
      max-width: 168px;
      @media screen and (max-width: 1125px) {
        max-width: 145px;
      }

      @media ${({ theme: { tablet } }) => tablet} {
        max-width: 126px;
        width: 22vw;
      }

      @media ${({ theme: { mobile } }) => mobile} {
        max-width: 98px;
      }
    }
  }
`;

const StyledFourthCardImages = styled.div`
  ${({ theme: { flex } }) => flex()};
  display: block;
  position: relative;
  margin-right: 20px;
  left: -30px;
  column-gap: 10px;

  @media ${({ theme: { tablet } }) => tablet} {
    margin-right: 0;
    left: 0;
  }

  img {
    &:first-child {
      max-width: 420px;
      @media screen and (max-width: 1125px) {
        max-width: 360px;
      }

      @media ${({ theme: { tablet } }) => tablet} {
        width: 65vw;
      }
    }

    &:last-child {
      max-width: 198px;
      position: absolute;
      right: -40px;
      bottom: 25px;
      @media screen and (max-width: 1125px) {
        max-width: 150px;
      }

      @media ${({ theme: { tablet } }) => tablet} {
        left: 50%;
        width: 30vw;
      }
    }
  }
`;

const StyledFifthCardImages = styled(StyledFirstCardImages)`
  ${({ theme: { flex } }) => flex()};
  margin-right: 20px;
  column-gap: 10px;

  @media ${({ theme: { tablet } }) => tablet} {
    margin-right: 0;
  }

  img {
    max-width: 483px;
    width: 100%;
    @media screen and (max-width: 1125px) {
      max-width: 370px;
    }

    @media ${({ theme: { mobile } }) => mobile} {
      max-width: 258px;
    }
  }
`;

const StyledTexts = styled.div`
  width: 42%;
  @media ${({ theme: { tablet } }) => tablet} {
    width: 100%;
    margin-top: 32px;
  }

  @media ${({ theme: { mobile } }) => mobile} {
    margin-top: 24px;
  }
`;

const StyledCardTitle = styled.div`
  margin-bottom: 24px;
  color: ${({ theme: { colors } }) => colors.white};
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -5%;

  strong {
    color: ${({ theme: { colors } }) => colors.orange};
  }

  @media ${({ theme: { tablet } }) => tablet} {
    font-size: 24px;
  }

  @media ${({ theme: { mobile } }) => mobile} {
    font-size: 22px;
  }
  @media screen and (max-width: 410px) {
    font-size: 20px;
  }
`;

const StyledFourthCardTitle = styled(StyledCardTitle)`
  position: relative;

  @media ${({ theme: { tablet } }) => tablet} {
    display: none;
  }
`;

const StyledMobileFourthCardTitle = styled(StyledCardTitle)`
  display: none;
  position: relative;

  @media ${({ theme: { tablet } }) => tablet} {
    display: block;
  }
`;

const StyledFifthCardTitle = styled(StyledFourthCardTitle)``;

const StyledMobileFifthCardTitle = styled(StyledMobileFourthCardTitle)``;

const StyledCardParagraph = styled.p`
  color: ${({ theme: { colors } }) => colors.white};
  text-align: left;
  font-family: "Pretendard-Regular";
  /* font-weight: 500; */
  line-height: 150%;

  @media ${({ theme: { tablet } }) => tablet} {
    text-align: center;
    font-family: "Pretendard-Regular";
  }

  @media ${({ theme: { mobile } }) => mobile} {
    line-height: 6vw;
    /* font-size: 14px; */
  }
`;

const StyledHotIcon = styled.div`
  position: absolute;
  top: -30px;
  right: 130px;
  background-size: contain;
  background-repeat: no-repeat;
  min-width: 50px;

  img {
    min-width: 50px;
  }

  div {
    position: absolute;
    color: ${({ theme: { colors } }) => colors.white};
    font-size: 15px;
    font-weight: 800;
    top: 43%;
    left: 51%;
    transform: translate(-50%, -50%);
  }
`;

const StyledTabletHotIcon = styled(StyledHotIcon)`
  right: -30px;
  top: -40px;
  @media ${({ theme: { mobile } }) => mobile} {
    right: 0;
    top: -45px;
  }
`;

const StyledMobileTitleImage = styled.img`
  display: none;
  margin-bottom: 49px;
  max-width: 213px;
  width: 100%;

  @media ${({ theme: { mobile } }) => mobile} {
    display: inline-block;
  }
`;
