import React, { useState } from "react";
import { theme } from "./styles";
import styled from "styled-components";
import { useEffect } from "react";

function Banner() {
  const [bannerState, setBannerState] = useState(true);

  const handleCloseClick = () => {
    setBannerState(false);
  };

  return bannerState ? (
    <>
      <ClosedBack>&nbsp;</ClosedBack>
      <StyledTopBanner>
        <StyledTopBannerWrap>
          <StyledTopBannerText>
            샘오더 출시기념 선착순 10명
            <span style={{ color: "#FFBB3E" }}>&nbsp;25%&nbsp;</span>
            할인
          </StyledTopBannerText>
          <StyledTopBannerCloseWrap onClick={handleCloseClick}>
            <StyledCloseDiv />
          </StyledTopBannerCloseWrap>
        </StyledTopBannerWrap>
      </StyledTopBanner>
    </>
  ) : null;
}

const ClosedBack = styled.div`
  height: 50px;
  @media screen and ${theme.tablet} {
    height: 50px;
  }

  @media screen and ${theme.mobile} {
    height: 40px;
    width: 100vw;
  }
`

const StyledTopBanner = styled.div`
  position: fixed;
  top: 0px;

  background: #085a1f;
  height: 50px;
  width: 100%;

  z-index: 1000;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  @media screen and ${theme.tablet} {
    height: 50px;
  }

  @media screen and ${theme.mobile} {
    height: 40px;
    width: 100vw;
  }
`;

const StyledTopBannerWrap = styled.div`
  max-width: 1200px;
  width: 100%;
  height: 50px;
  padding: 0 24px;

  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and ${theme.mobile} {
    height: 40px;
  }
`;

const StyledTopBannerText = styled.div`
  font-family: "Pretendard-SemiBold";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;

  width: calc(100% - 20px);

  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  text-align: center;

  @media screen and ${theme.mobile} {
    font-size: 14px;
  }
`;

const StyledTopBannerCloseWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  @media screen and ${theme.mobile} {
    width: 16px;
    height: 16px;
  }
`;

const StyledCloseDiv = styled.div`
  width: 2px;
  height: 20px;

  background: #ffffff;
  border-radius: 1px;
  transform: rotate(45deg);

  &:after {
    display: flex;
    content: "";
    width: 2px;
    height: 20px;
    background-color: #ffffff;
    border-radius: 1px;
    transform: rotate(90deg);
  }

  @media screen and ${theme.mobile} {
    height: 16px;

    &:after {
      display: flex;
      content: "";
      width: 2px;
      height: 16px;
      background-color: #ffffff;
      border-radius: 1px;
      transform: rotate(90deg);
    }
  }
`;

export default Banner;
