import React from "react";

import styled from "styled-components";
import mainTitle from "../../images/samorder/main_title.svg";
import subTitle from "../../images/samorder/main_sub_title.svg";
import mainKiosk from "../../images/samorder/main_kiosk.png";
import mainKioskShadow from "../../images/samorder/main_kiosk_shadow.png";
import mainFunction from "../../images/samorder/main_section_function.svg";
import mainTabletFunction from "../../images/samorder/main_section_function_tablet.svg";
import mainMobileFunction from "../../images/samorder/main_section_function_mobile.svg";
import theme from "./styles/theme";

function MainSection() {
  return (
    <StyledMainSection>
      <Background />
      <StyledSubTitle alt="main sub title" src={subTitle} />
      <StyledMainTitle alt="main title" src={mainTitle} />
      <StyledDescription>SAM ORDER 외식매장 키오스크</StyledDescription>
      <StyledMainKioskImageContainer>
        <img alt="main kiosk" src={mainKiosk} />
        <img alt="main kiosk shadow" src={mainKioskShadow} />
      </StyledMainKioskImageContainer>
      <StyledFunctionImagesContainer>
        <StyledFunctionImage alt="function image" src={mainFunction} />
        <StyledFunctionTabletImage
          alt="function tablet image"
          src={mainTabletFunction}
        />
        <StyledFunctionMobileImage
          alt="function mobile image"
          src={mainMobileFunction}
        />
      </StyledFunctionImagesContainer>
    </StyledMainSection>
  );
}

export default MainSection;

const Background = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: -1;
  background: radial-gradient(26% 35% at 46% 36.5%, #a71717 0%, #430000 100%);
  transform: rotate(30deg);

  @media screen and (max-width: 1280px) {
    background: radial-gradient(33% 35% at 46% 36.5%, #a71717 0%, #430000 100%);
    transform: rotate(25deg);
  }

  @media screen and ${({ theme: { tablet } }) => tablet} {
    background: radial-gradient(50% 30% at 50% 35%, #a71717 0%, #430000 100%);
    transform: rotate(10deg);
  }

  @media screen and ${({ theme: { mobile } }) => mobile} {
    background: radial-gradient(50% 23% at 47% 35%, #a71717 0%, #430000 100%);
  }
`;

const StyledMainSection = styled.section`
  ${({ theme: { flex } }) => flex("column")};
  position: relative;
  padding: 94px 0;
  /* background: radial-gradient(
    35.05% 20.5% at 50.01% 32.92%,
    #a71717 0%,
    #430000 100%
  ); */
  background-color: #430000;
  z-index: -2;
  overflow: hidden;

  &:after {
    /* background: linear-gradient(-45deg, #ffffff 20px, transparent 0),
      linear-gradient(45deg, #ffffff 20px, transparent 0); */
    background-position: left-bottom;
    background-repeat: repeat-x;
    background-size: 40px 35px;
    content: " ";
    display: block;
    position: absolute;
    bottom: 13px;
    left: 0px;
    width: 100%;
    height: 31px;
    transform: scale(2);
  }
`;

const StyledSubTitle = styled.img`
  margin-bottom: 24px;
  max-width: 303px;
  width: 100%;

  @media screen and ${({ theme: { mobile } }) => mobile} {
    margin-bottom: 16px;
    max-width: 202px;
  }
`;

const StyledMainTitle = styled.img`
  margin-bottom: 19px;
  max-width: 557px;
  padding: 0 30px;
  width: 100%;

  @media ${({ theme: { mobile } }) => mobile} {
    max-width: 344px;
  }
`;

const StyledDescription = styled.p`
  margin-bottom: 52px;
  color: ${({ theme: { colors } }) => colors.white};
  font-size: 20px;
  font-weight: 600;

  @media screen and ${({ theme: { tablet } }) => tablet} {
    margin-bottom: 69px;
  }

  @media screen and ${({ theme: { mobile } }) => mobile} {
    font-size: 14px;
    margin-bottom: 80px;
  }
`;

const StyledMainKioskImageContainer = styled.div`
  ${({ theme: { flex } }) => flex("column")};
  position: relative;
  margin-bottom: 75px;
  padding: 0 30px;

  img {
    max-width: 438px;
    width: 100%;
    position: relative;

    @media screen and ${({ theme: { mobile } }) => mobile} {
      max-width: 300px;
    }

    &:last-child {
      padding: 0 30px;
      position: absolute;
      bottom: -30%;
    }
  }
`;

const StyledFunctionImagesContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  z-index: 2;
  @media screen and ${({ theme: { tablet } }) => tablet} {
    max-width: 660px;
  }
`;

const StyledFunctionImage = styled.img`
  width: 100%;
  margin-bottom: 20px;

  @media screen and ${({ theme: { tablet } }) => tablet} {
    display: none;
  }
`;

const StyledFunctionTabletImage = styled.img`
  display: none;
  margin-bottom: 30px;
  width: 100%;

  @media screen and ${({ theme: { tablet } }) => tablet} {
    display: block;
  }

  @media screen and ${({ theme: { mobile } }) => mobile} {
    display: none;
  }
`;

const StyledFunctionMobileImage = styled.img`
  display: none;
  margin-bottom: 30px;
  width: 100%;

  @media screen and ${({ theme: { mobile } }) => mobile} {
    display: block;
  }
`;
